import * as React from 'react';
import { User } from '../types/User';

interface UserContextProps {
    currentUser: User | undefined;
    setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const UserContext = React.createContext<UserContextProps>({
    currentUser: undefined,
    setCurrentUser: (value: React.SetStateAction<User | undefined>) => null
});

export const useUserContext = (): UserContextProps => {
    return React.useContext(UserContext);
}

export const UserContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [currentUser, setCurrentUser] = React.useState<User | undefined>(undefined);
    return <UserContext.Provider value={{ currentUser, setCurrentUser }}>{children}</UserContext.Provider>
}