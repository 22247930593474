import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';


export interface MessageInputProps {
    text: string;
    isSelf: boolean;
    timestamp?: string;
    name?: string;
}

const MessageInput: React.FC<MessageInputProps> = ({ text, isSelf, timestamp, name }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems={isSelf ? 'flex-end' : 'flex-start'}
            mt={2}
        >
            <Typography
                variant="caption"
                color="textSecondary"
                gutterBottom
                sx={{ mb: -0.5 }}
            >
                {name}
            </Typography>
            <Box
                bgcolor={isSelf ? '#4caf50' : '#e0e0e0'} // User message in green, AI in grey
                color={isSelf ? '#ffffff' : '#000000'}
                borderRadius={isSelf ? '16px 16px 0 16px' : '16px 16px 16px 0'}
                px={3}
                py={1}
                maxWidth="80%"
                sx={{
                    wordWrap: 'break-word', // Ensures long words are broken
                    overflowWrap: 'break-word', // Ensures long words don't overflow
                    whiteSpace: 'pre-wrap', // Ensures text maintains line breaks and wraps properly
                  }}
            >
                <Typography variant="body1">{text}</Typography>
                <Typography variant="caption" color={isSelf ? 'rgba(255,255,255,0.7)' : 'textSecondary'} display="block" align="right">
                    {timestamp}
                </Typography>
            </Box>
        </Box>
    );
}
export default MessageInput;