import * as React from 'react';
import { useLoadingContext } from './loadingContext';

interface ConfigContextProps {
    apiUrl: string;
    assistantId:string;
}

export const ConfigContext = React.createContext<ConfigContextProps | undefined>(undefined);

export const useConfigContext = (): ConfigContextProps => {
    const context = React.useContext(ConfigContext);
    if (!context) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context;
}

export const ConfigContextProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
    const [config, setConfig] = React.useState<ConfigContextProps | undefined>(undefined);
    const { showLoading, hideLoading } = useLoadingContext();
    React.useEffect(() => {
        const fetchConfig = async () => {
            try {
                showLoading();
                const response = await fetch('/config.json');
                if (!response.ok) {
                    throw new Error('Failed to load configuration');
                }
                const configData: ConfigContextProps = await response.json();
                setConfig(configData);
            } catch (error) {
                console.error('Failed to load config:', error);
            } finally {
                hideLoading();
            }
        };
        fetchConfig();
    }, []);
    
    return <React.Fragment> {config && (
        <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>)
    }
    </React.Fragment>
}