import * as React from 'react';
import commonStyles from '../../styles/Common.module.css';
import RegisterForm from './RegisterForm';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { Box } from '@mui/material';
import { RegisterFormViewModel } from '../../types/RegisterFormViewModel';

export interface RegisterProps {
    onSubmit: () => void;
    errors?: FieldErrors;
    control: Control<RegisterFormViewModel>;
    errorMsg: string | null;
    watch:UseFormWatch<RegisterFormViewModel>;
    isLoading:boolean;
}

const Register: React.FC<RegisterProps> = ({ errors, onSubmit, control, errorMsg, watch, isLoading }) => {
    return <>
        <Box className={`${commonStyles.PaddingTop}`} >
            <h2 className={`${commonStyles.TextAlignCenter} ${commonStyles.MarginBottom} `}> Register</h2>
            <div className={commonStyles.MarginBottom}>
                <RegisterForm onSubmit={onSubmit} isLoading={isLoading} errors={errors} control={control} errorMsg={errorMsg} watch = {watch} />
            </div>
        </Box>
    </>;
}

export default Register;
