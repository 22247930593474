import * as React from 'react';
import { Alert, Snackbar, SnackbarCloseReason } from '@mui/material';

export interface PopupWindowProps {
  message: string;
  // windowType: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>;
  windowType: "success" | "error" | "warning" | "info";
  durationTime?: number;
  open: boolean; // Open state from the hook
  handleOnClose: (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void; // Function to close the Snackbar
}

const PopupWindow: React.FC<PopupWindowProps> = ({ message, windowType, durationTime = 2500, handleOnClose, open }) => {
  return (
    <Snackbar open={open} autoHideDuration={durationTime} onClose={handleOnClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert severity={windowType} sx={{ width: '100%' }} onClose={handleOnClose}>
        {message}
      </Alert>
    </Snackbar>

  );
}

export default PopupWindow;