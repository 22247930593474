import React from 'react';
import { Box } from '@mui/material';
import './TypingIndicator.css'; // Import the CSS for the typing animation

const TypingIndicator: React.FC = () => {
  return (
    <Box display="flex" alignItems="center" mt={3}>
      <Box className="typing-indicator">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Box>
    </Box>
  );
};

export default TypingIndicator;