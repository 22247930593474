import axios, { AxiosRequestConfig } from "axios"
import { useConfigContext } from "../stores/configContext";
import { useState } from "react";
export const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setAbortController] = useState<AbortController | null>(null); // State to hold the cancel token source
  const config = useConfigContext();
  const axiosInstance = axios.create({
    baseURL: config.apiUrl, // Use the baseURL from context
    timeout: 20000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Add interceptors if needed
  axiosInstance.interceptors.request.use(
    (config) => {
      //   const token = localStorage.getItem('token');
      //   if (token) {
      //     config.headers.Authorization = `Bearer ${token}`;
      //   }
      setIsLoading(true);
      return config;
    },
    (error) => {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      setIsLoading(false);
      return response;
    },
    (error) => {
      setIsLoading(false);
      return Promise.reject(error);
    }
  );

  // // Function to cancel API request
  const cancelRequest = () => {
    if (abortController) {
      abortController.abort('Operation canceled');
      setAbortController(null); // Clear the cancel token source after cancellation
    }
  };

  const getRequest = async <T>(url: string, params: any = {}, config: AxiosRequestConfig = {}) => {
    const controller = new AbortController();
    setAbortController(controller);
    return await axiosInstance.get<T>(url, { ...config, signal: abortController?.signal });
  };

  const postRequest = async <T>(url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
    const controller = new AbortController();
    setAbortController(controller);
    return await axiosInstance.post<T>(url, body, { ...config, signal:abortController?.signal });
  };

  const putRequest = async <T>(url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
    const controller = new AbortController();
    setAbortController(controller);
    return await axiosInstance.put<T>(url, body, { ...config, signal: abortController?.signal });
  };

  const patchRequest = async <T>(url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
    return await axiosInstance.patch<T>(url, body, { ...config, signal: abortController?.signal });
  };

  const deleteRequest = async <T>(url: string, config: AxiosRequestConfig = {}) => {
    return await axiosInstance.delete<T>(url, { ...config, signal: abortController?.signal });
  };

  return {
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    isLoading,
    cancelRequest
  }
}