import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import { useNavigate } from "react-router-dom";
import Login from '../components/login/Login';
import { useForm, SubmitHandler } from 'react-hook-form';
import { LoginViewModel } from '../types/LoginViewModel';
import { useHttp } from '../hooks/useHttp';
import { AxiosResponse } from 'axios';

const LoginPage: React.FC = () => {
    const { currentUser, setCurrentUser } = useUserContext();
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const { control, handleSubmit, formState: { errors } } = useForm<LoginViewModel>();
    const navigate = useNavigate();
    const { postRequest, isLoading } = useHttp()
    const onSubmit: SubmitHandler<LoginViewModel> = (async (data: LoginViewModel, event) => {
        try {
            event?.preventDefault();
            const response: AxiosResponse = await postRequest("/login/", data);
            setErrorMsg("");
            setCurrentUser(response.data)
        } catch (err: any) {
            const erorrResp: AxiosResponse = err.response;
            if (erorrResp && erorrResp.data) {
                setErrorMsg(erorrResp.data.error);
            } else {
                setErrorMsg(err.message);
            }
        }
    });
    React.useEffect(() => {
        if (currentUser) {
            navigate('../');
        }
    }, [currentUser, navigate])

    return <>
        <Login errorMsg={errorMsg} isLoading={isLoading} onSubmit={handleSubmit(onSubmit)} control={control} errors={errors} />
    </>;
}

export default LoginPage;


