import { ValidationRule } from "react-hook-form"

// Validate for alphabet only
export const validatedLetterPattern: ValidationRule<RegExp> = {
    value: /[A-Za-z]+/,
    message: "Only alphabet please"
}

export const validatedRequired: ValidationRule<boolean> = {
    value: true,
    message: "This field is required"
}

// Validate for expired date, such as 10/2021
export const expiredValidatePattern: ValidationRule<RegExp> = {
    value: /\b((0?[1-9])|(1[0-2]))\/(\d{4})\b/,
    message: "Please make sure your format is mm/yyyy"
}

// Validate for number only
export const validatedNumberPattern: ValidationRule<RegExp> = {
    value: /\d+$/,
    message: "Only number please"
}

export const validatedPasswordPattern: ValidationRule<RegExp> = {
    value: /^(?=.*[a-zA-Z])(?=.*\d)[\w\W]{6,}$/,
    message: "Must be at least 6 characters long and contain both letters and numbers."
}