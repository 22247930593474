import * as React from 'react';
import { Logout } from '@mui/icons-material';
import { AppBar, Avatar, Box, Container, Divider, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../stores/userContext';

export interface NavigationBarProps {
}

const NavigationBar: React.FC<NavigationBarProps> = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { currentUser, setCurrentUser } = useUserContext();
    const navigate = useNavigate();
    const isUserOpen = Boolean(anchorElUser);
    const bigScreenMenuStyle = {
        mr: 2,
        display: { xs: 'none', md: 'flex' },
        // fontFamily: 'monospace',
        fontWeight: 700,
        color: 'inherit',
        textAlign: 'center',
        textDecoration: 'none',
        my: 2
    };
    const shortCutsName = (): string => {
        if (!currentUser) {
            return "";
        }
        const firstInitial = currentUser.firstName.charAt(0).toUpperCase();
        const lastInitial = currentUser.lastName.charAt(0).toUpperCase();
        return firstInitial + lastInitial;
    }
    const handleOpenNavMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    }, []);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClickProfileLink = () => {
        setAnchorElUser(null);
        navigate("/profile");
    };
    const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setCurrentUser(undefined);
        setAnchorElUser(null);
    }
    const linkActiveStyle = ({ isActive }: { isActive: boolean }) => ({
        color: isActive ? 'yellow' : 'inherit'
    });
    return <>
        {currentUser && (<AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={NavLink}
                        to="/"
                        // @ts-ignore: typescript does not allow style, but material UI allow this Button
                        style={linkActiveStyle}
                        sx={bigScreenMenuStyle}
                    >
                        Home
                    </Typography>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography component={NavLink} to="/" sx={{ textAlign: 'center',  textDecoration: 'none' }}>Home</Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseNavMenu}>
                                <Typography component={NavLink} to="/conversation" sx={{ textAlign: 'center',  textDecoration: 'none' }}>Conversation</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                            my: 2
                        }}
                    >
                        Home
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <MenuItem
                            onClick={handleCloseNavMenu}>
                            <Typography
                                color="inherit"
                                to="/conversation"
                                component={NavLink}
                                // @ts-ignore: typescript does not allow style, but material UI allow this Button extends NavLink
                                style={linkActiveStyle}
                                sx={bigScreenMenuStyle}>
                                Conversation</Typography>
                        </MenuItem>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <Tooltip title="account menu">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                size="small"
                                sx={{ p: 0 }}
                                aria-haspopup="true"
                            >
                                <Avatar sx={{ width: 32, height: 32 }}>{shortCutsName()}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorElUser}
                            id="profile-menu"
                            open={isUserOpen}
                            onClose={handleCloseUserMenu}
                            slotProps={{
                                paper: {
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={handleClickProfileLink}>
                                <Avatar /> Profile
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>)}
    </>;
}

export default NavigationBar;
