import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import { useParams } from "react-router-dom";

import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, InputLabel, List, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import UserInputArea from '../components/chat/UserInputArea';

import MessageList from '../components/chat/MessageList';
import { MessageInputProps } from '../components/chat/MessageInput';
import { GetCurrentNzDateTime } from '../helpers/DateHelper';
import { AxiosResponse } from 'axios';
import { useHttp } from '../hooks/useHttp';
import { useLoadingContext } from '../stores/loadingContext';
import { NotificationCreateModel } from '../types/NotificationCreateModel';

export interface MessageModel {
    needAiResponse: boolean;
    text: string;
    userId: number | undefined;
}
export interface MessageResponse {
    userId: number | null;
    runId: string;
    createdAt: string;
    text: string;
    user_name: string | undefined;
}

export interface ConversationStatusModel {
    conversationId: string;
    status: string;
}
export interface StatusOptionsViewModel {
    text: string;
    value: string;
}

const ChatPage: React.FC = () => {
    const { id } = useParams();
    const [isTyping, setIsTyping] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string>('');
    const [messages, setMessages] = React.useState<MessageInputProps[]>([]);
    const [needAiResponse, setNeedAiResponse] = React.useState(false);
    const { showLoading, hideLoading } = useLoadingContext();
    const { getRequest, cancelRequest, postRequest, patchRequest } = useHttp()
    const [status, setStatus] = React.useState('started');
    const { currentUser } = useUserContext();
    const [disableConversation, setDisableConversation] = React.useState<boolean>(false)
    const [conversationUserId, setConversationUserId] = React.useState<number | undefined>();

    const statusOptions = React.useMemo<StatusOptionsViewModel[]>(() => {
        return [
            {
                text: 'started',
                value: 'started'
            },
            {
                text: 'request staff help',
                value: 'request'
            },
            {
                text: 'completed',
                value: 'completed'
            },
        ]
    }, []);

    const isAdmin = React.useMemo<boolean>(() => {
        if (currentUser?.roleId === 3) {
            return false;
        } else {
            return true
        }
    }, [currentUser?.roleId]);


    React.useEffect(() => {
        const fetchData = async () => {
            try {
                showLoading();
                const conversationResp: AxiosResponse = await getRequest(`conversation/${id}`)
                const responseStatus = conversationResp.data.status;
                const response: AxiosResponse = await getRequest(`${id}/message`);
                const conversationMessage: MessageResponse[] = response.data;
                const oldMessages: MessageInputProps[] = [];
                for (let m of conversationMessage) {
                    let isMyMessage = false;
                    if (currentUser?.userId === m.userId) {
                        isMyMessage = true;
                    }
                    const oldMes: MessageInputProps = buildNewMessage(m.text, isMyMessage, m.createdAt, m.user_name);
                    oldMessages.push(oldMes);
                }
                setConversationUserId(conversationResp.data.userId);
                setMessages(oldMessages);
                setStatus(responseStatus);
                updateConversationStatus(responseStatus);
            } catch (error) {
                console.error('Failed to message data:', error);
            } finally {
                hideLoading();
            }
        };
        fetchData();
        return () => {
            cancelRequest();
        }
    }, [])

    const updateConversationStatus = (newStatus: string) => {
        if (isAdmin) {
            setDisableConversation(false);
            setNeedAiResponse(false);
            return;
        }
        if (newStatus === 'completed') {
            setDisableConversation(true);
            return;
        }
        setDisableConversation(false);
        setNeedAiResponse(true);
    }
    const buildNewMessage = (text: string, isSelf: boolean, timestamp: string = "", name = ""): MessageInputProps => {
        // Combine time first followed by date
        if (!timestamp) {
            timestamp = GetCurrentNzDateTime();
        }
        if (!name) {
            name = "Assistant";
        }
        const newMessageInput: MessageInputProps = {
            text: text,
            isSelf: isSelf,
            timestamp: timestamp,
            name: name
        }
        return newMessageInput;
    }

    const buildSendMessageDate = (message: string): MessageModel => {
        const sendDate: MessageModel = {
            needAiResponse: needAiResponse,
            text: message,
            userId: currentUser?.userId
        }
        return sendDate
    }

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') {
            return;
        }
        const newMessageInput = buildNewMessage(inputValue, true);
        setMessages(prevMessages => [...prevMessages, newMessageInput]);
        setInputValue('');
        if (needAiResponse) {
            setIsTyping(true);
        }
        const sendMessageData = buildSendMessageDate(inputValue);
        try {
            const response: AxiosResponse = await postRequest(`/${id}/sendmessage`, sendMessageData);
            if (needAiResponse) {
                const aiText = response.data.text;
                const aiTestInput = buildNewMessage(aiText, false);
                setMessages(prevMessages => [...prevMessages, aiTestInput]);
            }
            if (isAdmin) {
                const createNotification: NotificationCreateModel = {
                    toStaff: false,
                    senderId: currentUser?.userId,
                    receiverId: conversationUserId,
                    text: `A staff replied your conversation`,
                    conversationId: parseInt(id as string)
                }
                await postRequest(`notification`, createNotification);
            }
        } catch (error: any) {
            console.error('Failed to send message:', error);
        } finally {
            setIsTyping(false);
        }
    }
    const messageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }

    const handleAiRepsoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setNeedAiResponse(checked);
    }

    const handleStatusChange = async (event: SelectChangeEvent<string>) => {
        const newStatus = event.target.value;
        if (!isAdmin && newStatus === "completed") {
            const userConfirmed = window.confirm('Are you sure to complete this conversation?');
            if (!userConfirmed) {
                return;
            }
        }
        try {
            const requestData: ConversationStatusModel = {
                conversationId: id as string,
                status: newStatus
            }
            await patchRequest(`conversation/${id}`, requestData);
            setStatus(newStatus);
            updateConversationStatus(newStatus);
        } catch (error) {
            console.error('Failed to update status:', error);
        }
    };

    return <React.Fragment>
        <Grid container>
            <Grid item xs={12} >
                <Typography variant="h5" className="header-message"></Typography>
            </Grid>
        </Grid>
        <Grid container sx={{ width: '100%', height: '100%' }}>
            <Grid item xs={2} sx={{ marginRight: '2vh' }}>
                <Grid item xs={12} style={{ margin: '10px' }}>
                    <InputLabel id="conversation-status">Update conversation status</InputLabel>
                    <Select
                        labelId="conversation-status"
                        id="conversation-status"
                        value={status}
                        displayEmpty
                        disabled={disableConversation}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleStatusChange}
                        sx={{ minWidth: '90%' }}
                    >
                        {statusOptions.map((opt, index) => {
                            return (
                                <MenuItem key={index} value={opt.value}>{opt.text}</MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                {isAdmin || (<Grid item xs={12} style={{ margin: '10px' }}>
                    <FormGroup>
                        <FormControlLabel
                            disabled={disableConversation}
                            control={<Checkbox
                                checked={needAiResponse}
                                onChange={handleAiRepsoneChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />}
                            label="Enable AI Reponse" />
                        <br />
                        <Typography variant="caption" style={{ color: 'gray', fontStyle: 'italic' }}>
                            Notice: Human response is not real-time
                        </Typography>
                    </FormGroup>
                </Grid>)}
            </Grid>
            <Grid item xs={9} sx={{ border: '1px solid #e0e0e0' }}>
                <List sx={{ overflow: 'auto', height: '80vh' }}>
                    <MessageList isTyping={isTyping} messages={
                        messages
                    } />
                </List>
                <Divider />
                {disableConversation || (<UserInputArea sendMessage={handleSendMessage} messageInputChange={messageInputChange} inputValue={inputValue} />)}
            </Grid>
            <Grid item xs={1}>
            </Grid>
        </Grid>
    </React.Fragment >;
}

export default ChatPage;


