import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import { NavLink, useNavigate } from "react-router-dom";
import styles from '../styles/Common.module.css'
import { Column } from '../types/Column';
import { Box, Button, Grid } from '@mui/material';
import StickyHeadTable from '../components/data-table/StickyHeadTable';
import { useLoadingContext } from '../stores/loadingContext';
import { useHttp } from '../hooks/useHttp';
import { AxiosResponse } from 'axios';

const HomePage: React.FC = () => {
  const { currentUser } = useUserContext();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const { showLoading, hideLoading } = useLoadingContext();
  const { getRequest, cancelRequest } = useHttp()
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser) {
          showLoading();
          const response: AxiosResponse = await getRequest(`/notification/${currentUser.userId}`);
          setData(response.data);
        }
      } catch (error) {
        console.error('Failed to load data:', error);
      } finally {
        hideLoading();
      }
    };
    if (!currentUser) {
      navigate('/login');
      return;
    }
    fetchData();
    return () => {
      cancelRequest();
    }
  }, [currentUser, navigate]);


  const columns: Column[] = [
    { id: 'notificationId', label: 'ID', minWidth: 100 },
    { id: 'sender_name', label: 'Sender Name', minWidth: 120 },

    {
      id: 'text',
      label: 'Content',
      minWidth: 170
    },
    {
      id: 'conversation_status',
      label: 'Conversation Status',
      minWidth: 170
    },
    {
      id: 'createdDate',
      label: 'Created Date',
      minWidth: 170
    },
    {
      id: 'action',
      label: 'Actions',
      minWidth: 170,
      render: (row) => (
        <>
          <Button variant="contained" color="primary" to={`/chat/${row.conversationId}`} component={NavLink} >
            View
          </Button>
        </>
      )
    }
  ];

  return <React.Fragment>
    <Box>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <h2 className={styles.TextAlignCenter}>Hi, {currentUser?.firstName}. Your notification displayed as below</h2>
            {data.length === 0 ? (<div>No data to display</div>) : (<StickyHeadTable rows={data} columns={columns} />)}
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </Box>
  </React.Fragment>;
}

export default HomePage;
