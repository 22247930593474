import * as React from 'react';
import MessageInput, { MessageInputProps } from './MessageInput';
import TypingIndicator from './TypingIndicator';

export interface MessageListProps {
    messages: MessageInputProps[];
    isTyping: boolean;
}

const MessageList: React.FC<MessageListProps> = ({ messages, isTyping }) => {
    return (
        <>
            {messages.map((msg, index) => (
                <MessageInput text={msg.text} key={index} isSelf={msg.isSelf} name={msg.isSelf ? "" : msg.name} timestamp={msg.timestamp} />
            ))}
            {isTyping && <TypingIndicator />}
        </>
    );
}
export default MessageList;

