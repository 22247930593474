import * as React from 'react';

import commonStyles from '../../styles/Common.module.css';
import LoginForm from './LoginForm';
import { Control, FieldErrors } from 'react-hook-form';
import { Box } from '@mui/material';
import { LoginViewModel } from '../../types/LoginViewModel';

export interface LoginProps {
    onSubmit: () => void;
    errors?: FieldErrors;
    control: Control<LoginViewModel>;
    errorMsg: string | null;
    isLoading: boolean;
}

const Login: React.FC<LoginProps> = ({ errors, onSubmit, control, errorMsg, isLoading }) => {
    return <>

        <Box className={`${commonStyles.PaddingTop}`} >
            <h2 className={`${commonStyles.TextAlignCenter} ${commonStyles.MarginBottom} `}> Login</h2>
            <div className={commonStyles.MarginBottom}>
                <LoginForm isLoading={isLoading} onSubmit={onSubmit} errors={errors} control={control} errorMsg={errorMsg} />
            </div>
        </Box>
    </>;
}

export default Login;
