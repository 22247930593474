import React from 'react';
import { Control, Controller, FieldError, FieldValues, Validate, ValidationRule } from 'react-hook-form';
import { TextField } from '@mui/material';
import styles from '../../styles/Common.module.css';

export interface FormInputProps {
    placeholder?: string;
    name: string;
    control: Control;
    type?: string;
    pattern?: ValidationRule<RegExp>;
    required?: ValidationRule<boolean>;
    error?: FieldError | any;
    defaultValue?: string;
    validate?: Validate<any, FieldValues>;
    readonly?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({ name, placeholder, control, error, required, pattern, defaultValue = '', type = 'text', validate, readonly = false }) => {
    return <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ pattern, required, validate }}
        render={({ field }) => (
            <TextField
                className={styles.Width100}
                placeholder={placeholder}
                error={!!error}
                helperText={error?.message}
                type={type}
                inputProps={{ readOnly: readonly }}
                sx={readonly? {
                    backgroundColor: '#f5f5f5',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#bdbdbd',
                    },
                  }:{}}
                {...field}
            />
        )}
    />
}

export default FormInput;