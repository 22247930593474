import * as React from 'react';
import FormInput from '../shared/FormInput';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { Alert, Box, Button, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import commonStyles from '../../styles/Common.module.css';
import { RegisterFormViewModel } from '../../types/RegisterFormViewModel';
import { validatedLetterPattern, validatedPasswordPattern, validatedRequired } from '../../utils/ValidatePattern';
import { Link } from 'react-router-dom';

export interface RegisterFormProps {
    control: Control<RegisterFormViewModel>;
    onSubmit: () => void;
    errors?: FieldErrors;
    errorMsg: string | null;
    watch: UseFormWatch<RegisterFormViewModel>;
    isLoading: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ control, onSubmit, errors, errorMsg, watch ,isLoading }) => {
    const currentPassword = watch('password');
    return (
        <Box>
            <form onSubmit={onSubmit} >
                <Grid container>
                    {
                        errorMsg && (
                            <Grid container>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                                    <Alert severity="error" sx={{
                                        textAlign: 'left',
                                        marginBottom: 2,
                                        fontSize: '1.00rem'
                                    }}>{errorMsg}</Alert>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        )
                    }
                    <Grid container>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="email">
                                Email:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="email"
                                placeholder={''}
                                control={control as any}
                                error={errors?.email}
                                required={validatedRequired}
                                type='email'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>

                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="password">
                                Password:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="password"
                                placeholder={''}
                                control={control as any}
                                error={errors?.password}
                                required={validatedRequired}
                                pattern={validatedPasswordPattern}
                                type='password'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="confirmPassword">
                                Confirm Password:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="confirmPassword"
                                placeholder={''}
                                control={control as any}
                                error={errors?.confirmPassword}
                                required={validatedRequired}
                                type='password'
                                validate={(value) => {
                                    if (value !== currentPassword){
                                        return 'Passwords do not match';
                                    }
                                    return true; }}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="firstName">
                                First Name:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="firstName"
                                placeholder={''}
                                pattern={validatedLetterPattern}
                                control={control as any}
                                error={errors?.firstName}
                                required={validatedRequired}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="lastName">
                                Last Name:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="lastName"
                                placeholder={''}
                                pattern={validatedLetterPattern}
                                control={control as any}
                                error={errors?.lastName}
                                required={validatedRequired}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={3}>
                            Already have an account?
                            <Link to="/login" > Sign In</Link>
                        </Grid>
                        <Grid item xs={1}>
                            <Button type="submit" disabled={isLoading} variant="contained" data-testid="login-submit-button" className={commonStyles.Width100}>
                                Register
                            </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Grid>
            </form >
        </Box>

    );
}

export default RegisterForm;