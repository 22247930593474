export const GetCurrentNzDateTime = (): string => {
    const currentDate = new Date();
    const options: any = {
        timeZone: 'Pacific/Auckland',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        year: 'numeric',
        month: '2-digit',
        day: 'numeric',
        hour12: false,
    };

    // Format the current date and time to New Zealand Time
    const nzDateTime = currentDate.toLocaleString('en-NZ', options);
    // Split the result to rearrange time and date
    const [date, time] = nzDateTime.split(', ');
    // Combine time first followed by date
    const nzTime = `${time} ${date}`;
    return nzTime;
}