import * as React from 'react';
import { Fab, Grid, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';


export interface UserInputAreaProps{
    sendMessage: () => void;
    messageInputChange:(event:React.ChangeEvent<HTMLInputElement>)=> void;
    inputValue?:string;
}

const UserInputArea: React.FC<UserInputAreaProps> = ({ sendMessage, messageInputChange, inputValue }) => {
    return (
        <Grid container style={{ padding: '20px' }}>
            <Grid item xs={11}>
                <TextField id="outlined-basic-email" label="Type Something"
                 fullWidth 
                 required 
                 onChange={messageInputChange} 
                 value={inputValue}
                  />
            </Grid>
            <Grid xs={1} item sx={{ textAlign: 'right' }}>
                <Fab color="primary" aria-label="add" onClick={sendMessage} disabled={!inputValue?.trim()}><Send /></Fab>
            </Grid>
        </Grid>
    );
}
export default UserInputArea;

