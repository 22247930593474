import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import PopupWindow from '../components/shared/PopupWindow';
import { SnackbarCloseReason } from '@mui/material';

interface PopupContextType {
  showPopup: (message: string, type: "success" | "error" | "warning" | "info", durationTime?: number) => void;
  closePopup: (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
}

const PopupContext = createContext<PopupContextType | undefined>({
  showPopup: (message: string, type: "success" | "error" | "warning" | "info", durationTime?: number) => null,
  closePopup: (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => null
});

export const usePopupContext = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};

export const PopupProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [windowType, setWindowType] = useState<"success" | "error" | "warning" | "info">('success');
  const [durationTime, setDurationTime] = useState<number>(2500);

  const showPopup = React.useCallback((message: string, type: "success" | "error" | "warning" | "info", durationTime = 2500) => {
    setMessage(message);
    setWindowType(type);
    setDurationTime(durationTime);
    setOpen(true);
  }, [])

  const closePopup = React.useCallback((event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, [])

  return (
    <PopupContext.Provider value={{ showPopup, closePopup }}>
      {children}
      <PopupWindow message={message} windowType={windowType} open={open} handleOnClose={closePopup} durationTime={durationTime} />
    </PopupContext.Provider>
  );
};
