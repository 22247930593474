import * as React from 'react';
import FormInput from '../shared/FormInput';
import { Control, FieldErrors } from 'react-hook-form';
import { Alert, Box, Button, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import commonStyles from '../../styles/Common.module.css';
import { LoginViewModel } from '../../types/LoginViewModel';
import { validatedRequired } from '../../utils/ValidatePattern';
import { Link } from 'react-router-dom';

export interface LoginFormProps {
    control: Control<LoginViewModel>;
    onSubmit: () => void;
    errors?: FieldErrors;
    errorMsg: string | null;
    isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({ control, onSubmit, errors, errorMsg, isLoading }) => {
    return (
        <Box>
            <form onSubmit={onSubmit} >
                <Grid container>
                    {
                        errorMsg && (
                            <Grid container>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                                    <Alert severity="error" sx={{
                                        textAlign: 'left',
                                        marginBottom: 2,
                                        fontSize: '1.00rem'
                                    }}>{errorMsg}</Alert>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        )
                    }
                    <Grid container>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="email">
                                Email:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="email"
                                placeholder={''}
                                control={control as any}
                                error={errors?.email}
                                required={validatedRequired}
                                type='email'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="password">
                                Password:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="password"
                                placeholder={''}
                                control={control as any}
                                error={errors?.password}
                                required={validatedRequired}
                                type='password'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>
                        
                        <Grid item xs={3}>
                        Don't have an account?
                        <Link to="/register" > Register Now</Link>
                        </Grid>
                        <Grid item xs={1}>
                            <Button type="submit" disabled={isLoading} variant="contained" data-testid="login-submit-button" className={commonStyles.Width100}>
                                Login
                            </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Grid>
            </form >

        </Box>

    );
}

export default LoginForm;