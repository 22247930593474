import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import { useNavigate } from "react-router-dom";
import Register from '../components/register/Register';
import { useForm, SubmitHandler } from 'react-hook-form';
import { RegisterFormViewModel } from '../types/RegisterFormViewModel';
import { AxiosResponse } from 'axios';
import { useHttp } from '../hooks/useHttp';
import { usePopupContext } from '../stores/popupContext';

const RegisterPage: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const { currentUser, setCurrentUser } = useUserContext();
    const { postRequest, isLoading } = useHttp()
    const { control, handleSubmit, formState: { errors }, watch } = useForm<RegisterFormViewModel>();
    const { showPopup } = usePopupContext();
    const navigate = useNavigate();
    const onSubmit: SubmitHandler<RegisterFormViewModel> = (async (data: RegisterFormViewModel) => {
        try {
            const response: AxiosResponse = await postRequest("/user/", data);
            setErrorMsg("");
            setCurrentUser(response.data)
            showPopup("sign up successfully", "success");
        } catch (err: any) {
            const erorrResp: AxiosResponse = err.response;
            if (erorrResp && erorrResp.data) {
                let respErrorMsg = "";
                for (let e in erorrResp.data) {
                    respErrorMsg += `${e} - ${erorrResp.data[e]}`;
                }
                setErrorMsg(respErrorMsg);
            } else {
                setErrorMsg(err.message);
            }
        }
    });
    // Check if firstname is empty, it should redirect to home page.
    React.useEffect(() => {
        if (currentUser) {
            navigate('/');
        }
    }, [currentUser, navigate])

    return <>
        <Register isLoading={isLoading} errorMsg={errorMsg} onSubmit={handleSubmit(onSubmit)} control={control} errors={errors} watch={watch} />
    </>;
}

export default RegisterPage;


