import { Navigate, useRoutes } from "react-router-dom"
import HomePage from "./HomePage";
import LoginPage from "./LoginPage";
import RegisterPage from "./RegisterPage";
import MainLayout from "./MainLayout";
import ProfilePage from "./ProfilePage";
import ChangePasswordPage from "./ChangePasswordPage";
import ConversationListPage from "./ConversationListPage";
import ChatPage from "./ChatPage";

export const AppRoutes = () => {
    const routes = useRoutes([
        {
            path: '/', element: <MainLayout />, children: [
                { path: '/', element: <HomePage /> },
                { path: 'profile', element: <ProfilePage /> },
                { path: 'new-password', element: <ChangePasswordPage /> },
                { path: 'conversation', element: <ConversationListPage /> },
                { path: 'chat/:id', element: <ChatPage /> },
                { path: 'chat', element: <ChatPage /> }
            ]
        },
        { path: '/home', element: <Navigate to="/" replace /> },
        { path: '/login', element: <LoginPage /> },
        { path: '/register', element: <RegisterPage /> },
        { path: '/*', element: <MainLayout /> }
    ]);
    return routes;
}