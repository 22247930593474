import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import commonStyles from '../styles/Common.module.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHttp } from '../hooks/useHttp';
import ProfileForm from '../components/profile/ProfileForm';
import { Box } from '@mui/material';
import { ProfileFormViewModel } from '../types/ProfileFormViewModel';
import { usePopupContext } from '../stores/popupContext';
import { User } from '../types/User';

const ProfilePage: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const { currentUser, setCurrentUser } = useUserContext();
    const { patchRequest, isLoading } = useHttp()
    const { control, handleSubmit, formState: { errors } } = useForm<ProfileFormViewModel>();
    const { showPopup } = usePopupContext();
    const onSubmit: SubmitHandler<ProfileFormViewModel> = (async (data: ProfileFormViewModel) => {
        try {
            await patchRequest(`/user/${currentUser?.userId}`, data);
            setErrorMsg("");
            const updatedUser: User = { ...currentUser, firstName: data.firstName, lastName: data.lastName } as User;
            setCurrentUser(updatedUser)
            showPopup("update successfully", "success")
        } catch (err: any) {
            const erorrResp: AxiosResponse = err.response;
            if (erorrResp && erorrResp.data) {
                let respErrorMsg = "";
                for (let e in erorrResp.data) {
                    respErrorMsg += `${e} - ${erorrResp.data[e]}`;
                }
                setErrorMsg(respErrorMsg);
            } else {
                setErrorMsg(err.message);
            }
        }
    });

    return <>
        <Box className={`${commonStyles.PaddingTop}`} >
            <h2 className={`${commonStyles.TextAlignCenter} ${commonStyles.MarginBottom} `}> Profile Management</h2>
            <div className={commonStyles.MarginBottom}>
                <ProfileForm currentUser={currentUser} onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} errors={errors} control={control} errorMsg={errorMsg} />
            </div>
        </Box>
    </>;
}

export default ProfilePage;