import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserContextProvider } from '../stores/userContext';
import { ConfigContextProvider } from '../stores/configContext';
import { AppRoutes } from './AppRoutes';
import { PopupProvider } from '../stores/popupContext';
import { LoadingProvider } from '../stores/loadingContext';

function App() {
  return (
    <LoadingProvider>
      <ConfigContextProvider>
        <PopupProvider>
          <UserContextProvider>
            <Router>
              <AppRoutes />
            </Router>
          </UserContextProvider>
        </PopupProvider>
      </ConfigContextProvider>
    </LoadingProvider>
  );
}

export default App;
