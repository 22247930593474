import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import StickyHeadTable from '../components/data-table/StickyHeadTable';
import { useHttp } from '../hooks/useHttp';
import { useLoadingContext } from '../stores/loadingContext';
import { AxiosResponse } from 'axios';
import { Column } from '../types/Column';
import { Box } from '@mui/system';
import { Button, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import commonStyles from '../styles/Common.module.css'
import { NavLink, useNavigate } from 'react-router-dom';
import { useConfigContext } from '../stores/configContext';
import { NotificationCreateModel } from '../types/NotificationCreateModel';

const ConversationListPage: React.FC = () => {
    const { currentUser } = useUserContext();
    const { getRequest, cancelRequest, postRequest } = useHttp()
    const [data, setData] = React.useState([]);
    const [filterData, setFilterData] = React.useState([])
    const [searchStatus, setSearchStatus] = React.useState('all')
    const { showLoading, hideLoading } = useLoadingContext();
    const config = useConfigContext();
    const navigate = useNavigate();

    const columns: Column[] = [
        { id: 'conversationId', label: 'ID', minWidth: 100 },
        { id: 'user_name', label: 'User Full Name', minWidth: 120 },
        {
            id: 'createdDate',
            label: 'Created Date',
            minWidth: 170
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 100
        },
        {
            id: 'action',
            label: 'Actions',
            minWidth: 170,
            render: (row) => (
                <>
                    <Button variant="contained" color="primary" to={`/chat/${row.conversationId}`} component={NavLink} >
                        View
                    </Button>
                </>
            )
        }
    ];

    const isAdmin = React.useMemo<boolean>(() => {
        if (currentUser?.roleId === 3) {
            return false;
        } else {
            return true
        }
    }, [currentUser?.roleId]);

    const handleCreateConversation = async () => {
        const conversationData = {
            status: "started",
            assistantId: config.assistantId,
            userId: currentUser?.userId
        };
        try {
            showLoading();
            const response: AxiosResponse = await postRequest(`/${currentUser?.userId}/conversation`, conversationData);
            const createNotification: NotificationCreateModel = {
                toStaff: true,
                senderId: currentUser?.userId,
                text: `A conversation has been created`,
                conversationId: response.data.conversationId
            }
            await postRequest(`notification`, createNotification);
            navigate(`/chat/${response.data.conversationId}`);
        } catch (error: any) {
            const erorrResp: AxiosResponse = error.response;
            console.log("conversation create error:", erorrResp)
        } finally {
            hideLoading();
        }
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                if (currentUser) {
                    let userId = 0;
                    if (!isAdmin) {
                        userId = currentUser.userId;
                    }
                    showLoading();
                    const response: AxiosResponse = await getRequest(`/${userId}/conversation`);
                    setData(response.data);
                    setFilterData(response.data);
                }
            } catch (error) {
                console.error('Failed to load data:', error);
            } finally {
                hideLoading();
            }
        };
        fetchData();
        return () => {
            cancelRequest();
        }
    }, [currentUser]);

    const selectStatusOnChange = (event: SelectChangeEvent<string>) => {
        const newStatus = event.target.value as string;
        setSearchStatus(newStatus);
        if (newStatus === 'all') {
            setFilterData(data);
        } else {
            const newData = data.filter((x: any) => x.status === newStatus);
            setFilterData(newData);
        }
    };

    return <React.Fragment>
        <Box>
            <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <Grid item xs={12}>
                        <h1 className={`${commonStyles.TextAlignCenter} ${commonStyles.MarginBottom36}`}>Conversation</h1>
                    </Grid>
                    {isAdmin ?
                        (<Grid item container>
                            <Grid item xs={1}>
                                <InputLabel sx={{ marginTop: 2 }}>Search Status:</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    value={searchStatus}
                                    onChange={selectStatusOnChange}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ minWidth: '60%', marginBottom: 3 }}
                                >
                                    <MenuItem value="all">
                                        All
                                    </MenuItem>
                                    <MenuItem value="started">
                                        Started
                                    </MenuItem>  <MenuItem value="request">
                                        Request
                                    </MenuItem>  <MenuItem value="completed">
                                        Completed
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        ) :
                        (<h3> <Button variant="contained" onClick={handleCreateConversation}>Create</Button></h3>)}
                    {filterData.length === 0 ? (<div>No data to display</div>) : (<StickyHeadTable rows={filterData} columns={columns} />)}
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        </Box>
    </React.Fragment >;
}
export default ConversationListPage;
