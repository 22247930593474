import * as React from 'react';
import { useUserContext } from '../stores/userContext';
import commonStyles from '../styles/Common.module.css';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHttp } from '../hooks/useHttp';
import { Box } from '@mui/material';
import { usePopupContext } from '../stores/popupContext';
import { ChangePasswordViewModel } from '../types/ChangePasswordViewModel';
import ChangePasswordForm from '../components/profile/ChangePasswordForm';

const ChangePasswordPage: React.FC = () => {
    const [errorMsg, setErrorMsg] = React.useState<string>("");
    const { currentUser } = useUserContext();
    const { putRequest, isLoading } = useHttp()
    const { control, handleSubmit, formState: { errors }, watch } = useForm<ChangePasswordViewModel>();
    const { showPopup } = usePopupContext();
    const onSubmit: SubmitHandler<ChangePasswordViewModel> = (async (data: ChangePasswordViewModel) => {
        try {
            await putRequest(`/pwd/${currentUser?.userId}`, data);
            setErrorMsg("");
            showPopup("Save Password Successfully", "success")
        } catch (err: any) {
            const erorrResp: AxiosResponse = err.response;
            if (erorrResp && erorrResp.data) {
                let respErrorMsg = "";
                for (let e in erorrResp.data) {
                    respErrorMsg += `${erorrResp.data[e]}`;
                }
                setErrorMsg(respErrorMsg);
            } else {
                setErrorMsg(err.message);
            }
        }
    });

    return <>
           <Box className={`${commonStyles.PaddingTop}`} >
            <h2 className={`${commonStyles.TextAlignCenter} ${commonStyles.MarginBottom} `}> New Password</h2>
            <div className={commonStyles.MarginBottom}>
                <ChangePasswordForm watch={watch} onSubmit={handleSubmit(onSubmit)} isLoading={isLoading} errors={errors} control={control} errorMsg={errorMsg} />
            </div>
        </Box>
    </>;
}

export default ChangePasswordPage;
