import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
// import reportWebVitals from './reportWebVitals';

// async function enableMocking() {
//   if (process.env.NODE_ENV !== 'development') {
//     return;
//   }
//   const { worker } = await import('./mock-api/browser');
//   return worker.start();
// }

// enableMocking().then(() => {
//   const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
//   root.render(
//     // <React.StrictMode>
//       <App />
//     // </React.StrictMode>
//   );
//   reportWebVitals();
// });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);