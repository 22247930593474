import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import CircularSpinner from '../components/shared/CircularSpinner';
import { Box } from '@mui/material';

// Define the context
interface LoadingContextType {
  showLoading: () => void;
  hideLoading: () => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Custom hook to use the LoadingContext
export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};

// LoadingProvider component to wrap around your app
export const LoadingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const showLoading = React.useCallback(() => {
    setIsLoading(true);
  }, []);
  
  const hideLoading = React.useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <LoadingContext.Provider value={{ showLoading, hideLoading }}>
      {children}

      {/* Conditionally render loading spinner */}
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional background overlay
            zIndex: 1300, // Ensure it appears above all other content
          }}
        >
          <CircularSpinner />
        </Box>
      )}
    </LoadingContext.Provider>
  );
};
