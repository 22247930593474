import React from "react";
import NavigationBar from "../components/navigation-bar/NavigationBar";
import { Outlet, useNavigate } from "react-router-dom";
import { useUserContext } from "../stores/userContext";


const MainLayout: React.FC = () => {
    const { currentUser } = useUserContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!currentUser) {
          navigate('/login');
        }
      }, [currentUser, navigate]);
    return <React.Fragment>
      <NavigationBar />
        <Outlet />
    </React.Fragment>;
}

export default MainLayout;
