import * as React from 'react';
import FormInput from '../shared/FormInput';
import { Control, FieldErrors } from 'react-hook-form';
import { Alert, Box, Button, InputLabel } from '@mui/material';

import Grid from '@mui/material/Grid';
import commonStyles from '../../styles/Common.module.css';
import { validatedLetterPattern, validatedRequired } from '../../utils/ValidatePattern';
import { ProfileFormViewModel } from '../../types/ProfileFormViewModel';
import { User } from '../../types/User';
import { Link } from 'react-router-dom';

export interface ProfileFormProps {
    control: Control<ProfileFormViewModel>;
    onSubmit: () => void;
    errors?: FieldErrors;
    errorMsg: string | null;
    isLoading: boolean;
    currentUser?: User | undefined;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ control, onSubmit, errors, errorMsg, isLoading, currentUser }) => {
    return (
        <Box>
            <form onSubmit={onSubmit} >
                <Grid container>
                    {
                        errorMsg && (
                            <Grid container>
                                <Grid item xs={4}>
                                </Grid>
                                <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                                    <Alert severity="error" sx={{
                                        textAlign: 'left',
                                        marginBottom: 2,
                                        fontSize: '1.00rem'
                                    }}>{errorMsg}</Alert>
                                </Grid>
                                <Grid item xs={4}></Grid>
                            </Grid>
                        )
                    }
                    <Grid container>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="email">
                                Email:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="email"
                                control={control as any}
                                type='email'
                                defaultValue={currentUser?.email}
                                readonly={true}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="firstName">
                                First Name:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="firstName"
                                placeholder={''}
                                pattern={validatedLetterPattern}
                                control={control as any}
                                error={errors?.firstName}
                                required={validatedRequired}
                                defaultValue={currentUser?.firstName}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={4}>
                            <InputLabel sx={{
                                textAlign: 'right',
                                marginTop: 2,
                                marginRight: 2,
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: 'black'
                            }} htmlFor="lastName">
                                Last Name:
                            </InputLabel>
                        </Grid>
                        <Grid item xs={4} className={commonStyles.HideNumberArrows}>
                            <FormInput
                                name="lastName"
                                placeholder={''}
                                pattern={validatedLetterPattern}
                                control={control as any}
                                error={errors?.lastName}
                                required={validatedRequired}
                                defaultValue={currentUser?.lastName}
                                type='text'
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={commonStyles.MarginTop36}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={3}>
                            <Link to="/new-password" > Change Password</Link>
                        </Grid>
                        <Grid item xs={1}>
                            <Button type="submit" disabled={isLoading} variant="contained" data-testid="login-submit-button" className={commonStyles.Width100}>
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                </Grid>
            </form >
        </Box>

    );
}

export default ProfileForm;